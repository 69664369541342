<template>
  <div id="Authenticity">
    <!-- 床垫查真伪 -->
    <div class="spot">
      <!-- 中文两边带点的title组件 -->
      <div class="color_en">Mattress check</div>
      <div class="spot_zh">· 床垫查真伪 ·</div>
    </div>
    <div class="Authenticity_box">
      <div class="AntiCounterfeiting">
        <div class="labelS">
          <div class="zh">防伪码</div>
          <div class="en">Security Code</div>
        </div>
        <div class="input">
          <input
            type="text"
            v-model="SecurityCode"
            style="width: 100%; height: 100%; border: none"
            placeholder="请输入"
          />
        </div>
      </div>
      <div class="verification">
        <div class="labelS">
          <div class="zh">验证码</div>
          <div class="en">Verification Code</div>
        </div>
        <div class="input">
          <div class="code">
            <input
              type="text"
              v-model="VerificationCode"
              style="width: 100%; height: 100%; border: none"
              placeholder="请输入"
            />
          </div>
          <div class="ver_box" @click="change_ver">
            <img :src="verificationImg" alt="" />
          </div>
        </div>
      </div>
      <div class="btns">
        <div @click="subMit" style="cursor: pointer">提交信息</div>
        <div @click="Reset" style="cursor: pointer">重置</div>
      </div>
      <div class="message">温馨提示：只有本公司产品，才能使用，查询其真伪</div>
    </div>
  </div>
</template>

<script>
import { submitSecurityCode } from "../../../http/api";
export default {
  name: "",
  props: {},
  data() {
    return {
      verificationImg: "",
      timer: "",
      SecurityCode: "",
      VerificationCode: "",
    };
  },
  methods: {
    subMit() {
      if (this.SecurityCode != "" && this.VerificationCode != "") {
        submitSecurityCode({
          security_code: this.SecurityCode,
          code: this.VerificationCode,
          time: this.timer,
        }).then((res) => {
          if (res.message === "验证码错误") {
            this.$message.error(res.message);
            this.SecurityCode = "";
            this.VerificationCode = "";
          } else {
            if (res.data.type === 1) {
              this.$message.success("该床垫为真");
              this.SecurityCode = "";
              this.VerificationCode = "";
              this.change_ver()
            } else {
              this.$message.error("该床垫为仿造");
              this.SecurityCode = "";
              this.VerificationCode = "";
              this.change_ver()
            }
          }
        });
      } else {
        this.$message.error("请输入全部信息！");
        this.SecurityCode = "";
        this.VerificationCode = "";
      }
    },
    Reset() {
      (this.SecurityCode = ""), (this.VerificationCode = "");
    },
    change_ver() {
      this.timer = Date.parse(new Date());
      this.verificationImg = `http://admin.bayichuangdian.com/api/index/getCaptcha/?time=${this.timer}`;
    },
  },
  mounted() {
    this.timer = Date.parse(new Date());
    this.verificationImg = `http://admin.bayichuangdian.com/api/index/getCaptcha/?time=${this.timer}`;
  },
  watch: {},
};
</script>

<style scoped lang='scss'>
#Authenticity {
  width: 100%;
  margin-top: 51px;
  height: 610px;
  background-image: url("../../../assets/img/Authenticity.png");
  background-size: cover;
  .spot {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-top: 61px;
    .color_en {
      font-size: 24px;
      font-family: MicrosoftYaHei;
      line-height: 21px;
      color: #ffffff;
    }
    .spot_zh {
      font-size: 30px;
      font-family: MicrosoftYaHei;
      line-height: 40px;
      color: #ffffff;
    }
  }
  .Authenticity_box {
    width: 596px;
    margin: 0 auto;
    margin-top: 88px;
    .AntiCounterfeiting {
      width: 100%;
      height: 49px;
      display: flex;
      justify-content: space-between;
      .labelS {
        width: 133px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #d9d9d9;
      }
      .input {
        width: 401px;
        height: 100%;
        background: #fff;
        padding-left: 30px;
      }
      input:focus {
        outline: medium;
      }
      input::placeholder {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .verification {
      width: 100%;
      height: 49px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0 54px 0;
      .labelS {
        width: 133px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #d9d9d9;
      }
      .input {
        width: 431px;
        display: flex;
        justify-content: space-between;
        .code {
          width: 265px;
          height: 100%;
          padding-left: 30px;
          background: #fff;
          input:focus {
            outline: medium;
          }
          input::placeholder {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .ver_box {
          width: 123px;
          height: 100%;
          background: #fff;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .btns {
      width: 100%;
      height: 49px;
      display: flex;
      justify-content: center;
      div {
        width: 90px;
        height: 37px;
        border: 1px solid #ffffff;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 37px;
        color: #ffffff;
        text-align: center;
        margin-left: 20px;
      }
    }
    .message {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #c1c1c1;
      margin-top: 47px;
    }
  }
}
</style>