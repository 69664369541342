<template>
  <div id="Contcat">
    <!-- 联系我们 -->
    <div class="center_Contcat">
      <div class="left_box">
        <div class="color_en">Contact us</div>
        <div class="spot_zh">· 联系我们 ·</div>
        <div class="address_box">
          <div class="icon_box">
            <img src="../../../assets/img/address_black_icon.png" alt="" />
          </div>
          <div class="text_box">地址：{{ data.address }}</div>
        </div>
        <div class="tel_box">
          <div class="icon_box">
            <img src="../../../assets/img/tel_black_icon.png" alt="" />
          </div>
          <div class="text_box">电话：{{ data.tel }}</div>
        </div>
        <div class="email_box">
          <div class="icon_box">
            <img src="../../../assets/img/emali_black_iocn.png" alt="" />
          </div>
          <div class="text_box">邮箱：{{ data.email }}</div>
        </div>
        <div class="qrCode_box">
          <img :src="data.wechat_qrcode" alt="" />
          <div>公众号二维码</div>
        </div>
      </div>
      <div class="right_box">
        <baidu-map
          class="bm-view"
          :center="center"
          style="width: 100%; height: 100%"
          :zoom="15"
          :scroll-wheel-zoom="true"
        >
          <bm-marker
            :position="center"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
          >
          </bm-marker>
        </baidu-map>
      </div>
    </div>
  </div>
</template>

<script>
import { getContact } from "../../../http/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      center: { lat: "", lng: "" },
      data: {},
    };
  },
  methods: {
    getContactData() {
      getContact().then((res) => {
        this.data = res.data;
        this.center.lat = res.data.lat;
        this.center.lng = res.data.lng;
      });
    },
  },
  mounted() {
    this.getContactData();
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#Contcat {
  width: 100%;
  padding: 119px 0 224px 0;
  background-image: url("../../../assets/img/retailstore_back.png");
  background-size: 855px 722px;
  background-position-y: 232px;
  background-repeat: no-repeat;
  .center_Contcat {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .right_box {
      width: 678px;
      height: 573px;
    }
    .left_box {
      width: 500px;
      .color_en {
        font-size: 24px;
        font-family: MicrosoftYaHei;
        line-height: 21px;
        color: #784c0c;
        width: 148px;
        text-align: center;
      }
      .spot_zh {
        font-size: 30px;
        font-family: MicrosoftYaHei;
        line-height: 40px;
        color: #3a3939;
        margin-bottom: 96px;
      }
      .address_box {
        width: 100%;
        height: 32px;
        margin-bottom: 44px;
        display: flex;
        .icon_box {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            width: 19.97px;
            height: 100%;
          }
        }
        .text_box {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-left: 20px;
          line-height: 30px;
          color: #666666;
        }
      }
      .tel_box {
        width: 100%;
        margin-bottom: 44px;
        height: 32px;
        display: flex;
        .icon_box {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            width: 20.53px;
            height: 20.59px;
          }
          width: 30px;
          height: 30px;
        }
        .text_box {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          margin-left: 20px;
          line-height: 30px;
          color: #666666;
        }
      }
      .email_box {
        width: 100%;
        height: 32px;
        margin-bottom: 25px;
        display: flex;
        .icon_box {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: inline-block;
            width: 100%;
            height: 19.57px;
          }
          width: 30px;
          height: 30px;
        }
        .text_box {
          margin-left: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
          color: #666666;
        }
      }
      .qrCode_box {
        width: 100%;
        height: 134px;
        display: flex;
        align-items: center;
        img {
          display: block;
        }
        div {
          margin-left: 16px;
          width: 80px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 31px;
          color: #333333;
        }
      }
    }
  }
}
</style>