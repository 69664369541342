<template>
  <div id="RetailStore">
    <!-- 门店查询 -->
    <div class="baidu_map">
      <baidu-map
        class="bm-view baidu_m"
        :center="center"
        style="width: 100%; height: 100%"
        :zoom="15"
        :scroll-wheel-zoom="true"
      >
        <bm-marker
          :position="center"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        >
        </bm-marker>
      </baidu-map>
      <div class="right_position_box">
        <div class="form_box">
          <el-select
            v-model="provinceName"
            placeholder="请选择省份"
            class="select"
            @change="changeEd"
          >
            <el-option
              v-for="item in ProvinceList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="cityNmae"
            placeholder="请选择城市"
            class="select selectCity"
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div class="search_box" @click="search_sub">
            <img src="../../../assets/img/search_icon.png" alt="" />
            <div>搜索</div>
          </div>
        </div>
      </div>
    </div>
    <Spot
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
      style="margin-top: 100px"
    ></Spot>
    <div class="four_box">
      <div
        class="item_address"
        v-for="(item, index) in addressArr"
        :key="index"
      >
        <div class="left_box">
          <div class="storeName">{{ item.title }}</div>
          <div class="storeAddress">{{ item.address }}</div>
          <div class="storeTel">
            联系电话：<span>{{ item.tel }}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="right_box" @click="toBaiduMap(item)">
          <img src="../../../assets/img/address_icon.png" alt="" />
          <span>获取路线</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStoreList, getProvinceList, getCityList } from "../../../http/api";
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {},
  components: { Spot },
  data() {
    return {
      center: { lat: "", lng: "" },
      active_map_center: 0,
      ProvinceList: [],
      cityList: [],
      provinceName: "",
      cityNmae: "",
      SpotZhTitle: "门店查询",
      SpotEnTitle: "Retail store",
      addressArr: [],
    };
  },
  methods: {
    toBaiduMap(item) {
      window.open(`https://map.baidu.com`);
      // /search/${item.address}
    },
    getStoreListData(province, city) {
      if ((province, city)) {
        getStoreList({
          province,
          city,
        }).then((res) => {
          if (res.data.length > 0) {
            this.addressArr = res.data;
            this.center.lat = res.data[this.active_map_center].lat;
            this.center.lng = res.data[this.active_map_center].lng;
          } else {
            this.$message.warning("当前搜索城市暂无门店");
          }
        });
      } else {
        getStoreList().then((res) => {
          this.addressArr = res.data;
          this.center.lat = res.data[this.active_map_center].lat;
          this.center.lng = res.data[this.active_map_center].lng;
        });
      }
    },
    getCityListData(province) {
      getCityList({ province: province }).then((res) => {
        this.cityList = res.data;
      });
    },
    changeEd(val) {
      if (val) {
        this.getCityListData(val);
      }
    },
    search_sub() {
      if (this.provinceName && this.cityNmae) {
        this.getStoreListData(this.provinceName, this.cityNmae);
      } else {
        this.$message.error("请选择省份和城市！");
      }
    },
  },
  mounted() {
    getProvinceList().then((res) => {
      this.ProvinceList = res.data;
    });

    this.getStoreListData();
  },
  watch: {
    center(val) {
      console.log(val);
    },
  },
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#RetailStore {
  width: 100%;
  background-image: url("../../../assets/img/retailstore_back.png");
  background-size: 855px 722px;
  background-position-y: 232px;
  background-repeat: no-repeat;
  .baidu_map {
    width: 100%;
    margin-top: 31px;
    height: 573px;
    position: relative;
    .right_position_box {
      width: 47.5vw;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      right: 0;
      z-index: 9;
      top: 0;
      .form_box {
        width: 20.26vw;
        height: 207px;
        margin: 100px 78px;

        .select {
          width: 100% !important;
          height: 49px !important;
        }
        .selectCity {
          margin-top: 30px;
        }
        .search_box {
          width: 100%;
          height: 40px;
          border-radius: 8px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          background: #dcaf59;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          img {
            width: 20.66px;
            height: 20.66px;
            margin-right: 20px;
          }
        }
        // .el-select .el-input.is-focus .el-input__inner {
        //   border-color: transparent !important;
        // }
        // .el-select:hover .el-input__inner {
        //   border-color: transparent !important;
        // }
      }
    }
  }
  .four_box {
    width: 62.5vw;
    margin: 77px auto 188px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item_address {
      width: 30.41vw;
      height: 168px;
      background: #f9f9f9;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 40px;
      .left_box {
        width: 21.56vw;
        .storeName {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 30px;
          color: #333333;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .storeAddress {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #333333;
          margin: 20px 0 10px 0;
        }
        .storeTel {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 21px;
          color: #333333;
          span {
            color: #005a3f;
            margin-left: 5px;
          }
        }
      }
      .line {
        height: 87px;
        width: 1px;
        background: #707070;
      }
      .right_box {
        width: 56px;
        img {
          width: 25.04px;
          height: 32.59px;
          display: block;
          margin: 0 auto;
        }
        span {
          display: inline-block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 18px;
          color: #666666;
          margin-top: 18px;
        }
      }
    }
  }
}
</style>