<template>
  <div id="Maintain">
    <!-- 床垫保养知识 -->
    <Spot
      style="margin-top: 78px"
      :SpotEnTitle="SpotEnTitle"
      :SpotZhTitle="SpotZhTitle"
    ></Spot>
    <div class="vHtml" v-html="content"></div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
import { getSales } from "../../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      SpotEnTitle: "Mattress maintenance knowledge",
      SpotZhTitle: "床垫保养知识",
      content: "",
    };
  },
  methods: {},
  mounted() {
    getSales({ type: 2 }).then((res) => {
      this.content = res.data.text.replace(
        /<img/g,
        '<img style="max-width:100%;"'
      );
    });
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#Maintain {
  width: 100%;
  background-image: url("../../../assets/img/retailstore_back.png");
  background-size: 855px 722px;
  background-position-y: 232px;
  background-repeat: no-repeat;
  .vHtml {
    width: 1200px;
    margin: 45px auto 123px auto;
  }
}
</style>