<template>
  <div id="AfterSales">
    <!-- 售后服务 -->
    <!-- <div v-html="AfterSalesData"></div> -->
    <div class="address">地址：{{ AfterSalesData.address }}</div>
    <div class="tel">售后电话：{{ AfterSalesData.tel }}</div>
    <div class="image" v-if="AfterSalesData.image">
      <img class="my_cover" :src="AfterSalesData.image" alt="" />
    </div>
  </div>
</template>

<script>
import { getSales } from "../../../http/api";
export default {
  name: "",
  data() {
    return {
      AfterSalesData: {},
    };
  },
  mounted() {
    getSales({ type: 1 }).then((res) => {
      this.AfterSalesData = res.data.text;
    });
  },
};
</script>

<style scoped lang='scss'>
#AfterSales {
  width: 100%;
  margin-top: 54px;
  .address {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 31px;
    color: #3a3939;
    text-align: center;
  }
  .tel {
    margin-top: 20px;
    margin-bottom: 90px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 31px;
    color: #3a3939;
    text-align: center;
  }
  .image {
    width: 100%;
    height: 600px;
  }
}
</style>