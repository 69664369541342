<template>
  <div id="service">
    <!-- 为您服务 -->
    <Header id="Header"></Header>
    <div class="banner_box" v-if="crumbs_active == 0">
      <img :src="bannerImg" class="my_cover" alt="" />
    </div>
    <Crumbs :crumbs="second_menu" :crumbs_active="crumbs_active"></Crumbs>
    <Spot :SpotEnTitle="SpotEnTitle" :SpotZhTitle="SpotZhTitle" v-if="crumbs_active == 0" style="margin-top: 114px"></Spot>
    <AfterSales
      v-if="crumbs_active == 0"
      :crumbs_active="crumbs_active"
    ></AfterSales>
    <RetailStore v-if="crumbs_active == 1"></RetailStore>
    <Contcat v-if="crumbs_active == 2"></Contcat>
<!--    <Authenticity v-if="crumbs_active == 3 && AuthenticityFlag"></Authenticity>-->
<!--    <Maintain v-if="crumbs_active == 4"></Maintain>-->
    <Maintain v-if="crumbs_active == 3"></Maintain>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Crumbs from "../../components/crumbs";
import Spot from "../../components/spot";
import Contcat from "./components/Contcat";
import AfterSales from "./components/AfterSales";
import RetailStore from "./components/RetailStore";
import Authenticity from "./components/Authenticity";
import Maintain from "./components/Maintain";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Crumbs,
    AfterSales,
    Spot,
    RetailStore,
    Contcat,
    Authenticity,
    Maintain,
  },
  data() {
    return {
      bannerImg: "",
      AuthenticityFlag: true,
      crumbs_active: 0,
      SpotEnTitle: "After-sale service",
      SpotZhTitle: "售后服务",
      // second_menu: [
      //   {
      //     name: "售后服务",
      //   },
      //   {
      //     name: "门店查询",
      //   },
      //   {
      //     name: "联系我们",
      //   },
      //   {
      //     name: "床垫查真伪",
      //   },
      //   {
      //     name: "床垫保养知识",
      //   },
      // ],
      second_menu: [
        {
          name: "售后服务",
        },
        {
          name: "门店查询",
        },
        {
          name: "联系我们",
        },
        {
          name: "床垫保养知识",
        },
      ],
    };
  },
  methods: {},
  mounted() {
    this.crumbs_active = localStorage.getItem("crumbs_active") || this.$route.query.crumbs_active
      ? localStorage.getItem("crumbs_active") || this.$route.query.crumbs_active
      : 0;
      var obj = JSON.parse(localStorage.getItem("getBanner"));
      this.bannerImg = obj[3].image;
    
  },
  watch: {
    crumbs_active(val) {
      localStorage.setItem("crumbs_active", val);
    },
  },
  destroyed(){
    localStorage.removeItem('crumbs_active')
  }
};
</script>

<style scoped lang='scss'>
#service {
  .banner_box {
    width: 100%;
    // height: 850px;
    height: 44.27vw;
  }
}
</style>